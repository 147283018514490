<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Loading from '@/components/Loading.vue';
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
import ApplicationModal from "@/components/modals/application-modal";

export default {
	components: {
		Loading,
		Layout,
		PageHeader,
		ConfirmationModal,
		MessageModal,
		ApplicationModal,
	},
	data() {
		const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

		return {
			id: id,
			title: 'Application Details',
			modeName,
			items: [
				{
					text: "Assignment"
				},
				{
					text: 'Application details',
				},
			],
			validId: true,
			data: {
				requestor_name: '',
				phone: '',
				email: '',
				requestor_type: '',
				postal: '',
				type_of_lesson: '',
				student_name: '',
				student_level: '',
				status: '',
				service_id: '',
				service_name: '',
				subject_name: '',
				subject_duration_name:'',
				subject_frequency_name:'',
				subject_frequency_type:'',
				tutor_type:'',
				tutor_rate: '',
				other_tutor_type: '',
				preferred_budget: '',
				gender_preference: '',
				preferred_time: '',
				remarks: '',
				assigned_tutor_email: '',
				assigned_date: '',
				assignment_item_id: '',
				address: '',
				unit: '',
				estimated_commencement_date: '',
			},
			subject_duration_text: '',
            subject_frequency_text: '',
            tutor_type_text: '',
			gradeOptions: [],
			serviceOptions: [],
			subjectDurationOptions: [],
			subjectFrequencyOptions: [],
			tutorTypeOptions: [],
			tutorOptions: [],
			applicantList: [],
			errors: {},
			ableToChangeTutor: false,
			loading: true,
		};
	},
	async mounted() {
        const result = await axios.get(`${process.env.VUE_APP_APIURL}/assignment/${this.id}`, {
            headers: authHeader(),
        });

        if (result && result.data['code'] === 'success') {
            const data = result.data['data']['assignment'];

            for (const [key, item] of Object.entries(data)) {
                if (key in this.data) {
                    if (key !== 'items') {
                        this.data[key] = item;
                    }
                }
            }

            const item = data.items[0];
            if (item) {
                for (const [key, value] of Object.entries(item)) {
                    if (key in this.data) {
                        this.data[key] = value;
                    } else if (key == 'id') {
						this.data.assignment_item_id = value;
					}
                }
            }

			const applicants = data.items[0].applicants;
			if (applicants.length) {
                for (const applicant of applicants) {
                    this.applicantList.push(applicant);
                }
            }

			if (this.data.assigned_tutor_email) {
				this.ableToChangeTutor = true;
			}
			console.log('AL', this.applicantList)
        } else if (result && result.data['code'] === 'invalid_assignment') {
			this.validId = false;
			return;
		}

		this.retrieveSubjectFrequency().then((frequencies) => {
            const subject_frequency = frequencies.find(frequency => frequency.id == this.data.subject_frequency_name);
            if (subject_frequency) {
                this.subject_frequency_text = subject_frequency.frequency > 1 ? subject_frequency.frequency + ' lessons per week' : subject_frequency.frequency + ' lesson per week';
            } else {
                this.subject_frequency_text = '-';
            }
		});

		this.retrieveTutorTypes().then((types) => {
			const tutor_type = types.find(type => type.id == this.data.tutor_type);
            if (tutor_type) {
                this.tutor_type_text = tutor_type.name + ' ($' + tutor_type.rate + '/hr)';
            } else {
                this.tutor_type_text = '-';
            }
		});

		this.retrieveTutors().then((tutors) => {
			this.tutorOptions = tutors.map((tutor) => ({
				name: tutor.name,
				value: tutor.id,
			}));
		});

		this.loading = false;
	},
	computed: {
		formattedTutorInfo() {
            let tutorTypes = [];
            let tutorRates = [];

            try {
                tutorTypes = JSON.parse(this.data.tutor_type);
            } catch (error) {
                console.error("Failed to parse tutor_type:", error);
                return '-';
            }

			if (typeof this.data.tutor_rate === 'number') {
				tutorRates = this.data.tutor_rate;
			} else {
				try {
					tutorRates = JSON.parse(this.data.tutor_rate);
				} catch (error) {
					console.error("Failed to parse tutor_rate:", error);
					tutorRates = '';
					// return '-';
				}
			}

            if (!Array.isArray(tutorTypes)) {
                return '-';
            }

            // if (typeof tutorRates === 'number') {
			// 	let rateDisplay = this.data.service_id == 5 ? `$${tutorRates}/session` : `$${tutorRates}/hr`;
				
			// 	const formattedInfo = tutorTypes.map((type) => {
			// 		return `${type.name} (${rateDisplay})`;
			// 	});

			// 	// const formattedInfo = tutorTypes.map((type) => {
			// 	// 	return `${type.name} ($${tutorRates}/hr)`;
			// 	// });
			// 	return formattedInfo.join(', ');
			// } else {
			if (Array.isArray(tutorRates)) {
				// If tutorRates is an array, create a map and format accordingly
				const tutorRatesMap = tutorRates.reduce((acc, rate) => {
					acc[rate.id] = rate.rate;
					return acc;
				}, {});

				const formattedInfo = tutorTypes.map((type) => {
					console.log('typetype', tutorRatesMap)
					const rate = tutorRatesMap[type.id];

					let rateDisplay = rate;
					console.log('this.data', this.data)
					if (this.data.preferred_budget == 1) {
						rateDisplay = this.data.service_id == 5 
							? `$${rate}/session`
							: `$${rate}/hr`;
					}

					return `${type.name} (${rateDisplay})`;
				});

				// const formattedInfo = tutorTypes.map((type) => {
				// 	const rate = tutorRatesMap[type.id];
				// 	return `${type.name} ($${rate}/hr)`;
				// });
				return formattedInfo.join(', ');
			} else {
				return this.data.tutor_rate;
			}
        },
	},
	methods: {
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},

		formatString(input) {
			let words = input.split('_');
			
			for (let i = 0; i < words.length; i++) {
				words[i] = this.capitalizeFirstLetter(words[i]);
			}
			
			return words.join(' ');
		},

		formatDate(dateString) {
			const date = new Date(dateString);
			const options = { year: 'numeric', month: 'long', day: '2-digit' };
			return new Intl.DateTimeFormat('en-GB', options).format(date);
		},

		viewApplication(data) {
			this.$refs.applicationModal.showModal(data);
		},

		async retrieveSubjectFrequency() {
            this.loading = true;
            let subject_frequencies = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/subject_frequency`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    subject_frequencies = result['data'].subject_frequencys;
                }
            } catch (e) {
                console.error(e);
            }

            return subject_frequencies;
        },

		async retrieveTutorTypes() {
            this.loading = true;
            let tutor_types = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/tutor_type`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    tutor_types = result['data'].tutor_types;
                }
            } catch (e) {
                console.error(e);
            }
            
            return tutor_types;
        },

		async retrieveTutors() {
            this.loading = true;
            let tutors = [];
			let filter = {
				filter: {
					status: 'approved',
				}
			};

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/tutor`, {
                    headers: authHeader(),
					params: filter
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    tutors = result['data'].tutors;
                }
            } catch (e) {
                console.error(e);
            }
            
            return tutors;
        },

        async approve(data) {
            const requestData = {};
            requestData.tutor_id = data.tutor_id;

            const updateCallback = async () => {
                const result = await axios.post(`${process.env.VUE_APP_APIURL}/assign_tutor/${this.id}`, requestData, {
                    headers: authHeader(),
                });

                if (result) {
                    this.$refs.confirmationModal.closeModal();
                    if (result.data['code'] === 'success') {
                        this.$refs.messageModal.showModal('You\'ve successfully assigned this assignment to ' + data.name + '. They will be notified via email.');
                    } else {
                        this.$refs.messageModal.showModal('Something went wrong. Please try again later.');
                    }
                }
			};

			let modalContent = 'You are about to assign this assignment to:<br><ul><li>Service Provider: ' + data.name + '</li></ul>Are you sure you want to proceed?<br><br>If so, other applicants who apply for this assignment will be automatically denied for their application.';
			this.$refs.confirmationModal.showModal(modalContent, updateCallback);
        },

        async decline() {
            const requestData = {};
            requestData.status = 'rejected';
            
            const updateCallback = async () => {
                const result = await axios.post(`${process.env.VUE_APP_APIURL}/assignment/${this.id}`, requestData, {
                    headers: authHeader(),
                });

                if (result) {
                    this.$refs.confirmationModal.closeModal();
                    if (result.data['code'] === 'success') {
                        this.$refs.messageModal.showModal('Your record has been updated successfully');
                    } else {
                        this.$refs.messageModal.showModal('Something went wrong. Please try again later.');
                    }
                }   
			};
			this.$refs.confirmationModal.showModal('Decline this request?', updateCallback);
        },

		async changeTutor(tutor_id) {
			const requestData = {};
            requestData.tutor_id = tutor_id;
			requestData.assignment_item_id = this.data.assignment_item_id;

			const updateCallback = async () => {
                const result = await axios.post(`${process.env.VUE_APP_APIURL}/reset_tutor/${this.id}`, requestData, {
                    headers: authHeader(),
                });

                if (result) {
                    this.$refs.confirmationModal.closeModal();
                    if (result.data['code'] === 'success') {
                        this.$refs.messageModal.showModal('Updated.');
                    } else {
                        this.$refs.messageModal.showModal('Something went wrong. Please try again later.');
                    }
                }
			};

			this.$refs.confirmationModal.showModal('Confirm to reset?', updateCallback);
		}
	}
};
</script>

<template>
	<Layout>
		<Loading :isLoading="loading" />
		<PageHeader :title="title" :items="items" />
		<ConfirmationModal ref="confirmationModal" />
		<MessageModal ref="messageModal" />
		<ApplicationModal ref="applicationModal" @approve="approve" />

		<div v-if="!loading">
			<div v-if="!validId" class="mt-5 text-center">
				<h4>Assignment not found!</h4>
			</div>
			<div v-else>
				<div class="card">
					<div class="card-body">
						<p class="card__title">Assignment details</p>

						<div class="row">
							<div class="col-12 col-md-4 mb-4">
								<p class="label" v-if="data.service_id == 1 || data.service_id == 2">Subjects</p>
								<p class="label" v-else-if="data.service_id == 3 || data.service_id == 4">Therapy services</p>
								<p class="label" v-else-if="data.service_id == 5">Enrichment services</p>
								<p class="label" v-else>Subject</p>
								<p class="data">{{ data.subject_name ?? '-' }}</p>
							</div>
							<div class="col-12 col-md-4 mb-4">
								<p class="label" v-if="data.service_id == 1 || data.service_id == 2">Duration per subject</p>
								<p class="label" v-else-if="data.service_id == 3 || data.service_id == 4">Duration per type of therapy</p>
								<p class="label" v-else-if="data.service_id == 5">Duration per type of enrichment</p>
								<p class="label" v-else>Duration</p>
								<p class="data">{{ (data.subject_duration_name > 1 ? data.subject_duration_name + " minutes / lesson" : data.subject_duration_name + " minute / lesson") ?? '-' }}</p>
							</div>
							<div class="col-12 col-md-4 mb-4">
								<p class="label" v-if="data.service_id == 1 || data.service_id == 2">Frequency per subject</p>
								<p class="label" v-else-if="data.service_id == 3 || data.service_id == 4">Frequency per type of therapy</p>
								<p class="label" v-else-if="data.service_id == 5">Frequency per type of enrichment</p>
								<p class="label" v-else>Frequency</p>
								<p class="data">{{ (data.subject_frequency_name > 1 ? data.subject_frequency_name + " lessons per " + data.subject_frequency_type : data.subject_frequency_name + " lesson per " + data.subject_frequency_type) ?? '-' }}</p>
							</div>
							<div class="col-12 col-md-4 mb-4">
								<p class="label">Rates</p>
								<p v-if="formattedTutorInfo && data.other_tutor_type" class="data">
									{{ formattedTutorInfo + ', ' + data.other_tutor_type + (data.preferred_budget == 1 ? ' ($' + data.tutor_rate + (data.service_id == 5 ? '/session)' : '/hr)') : '') }}
								</p>
								<p v-else-if="formattedTutorInfo" class="data">
									{{ formattedTutorInfo }}
								</p>
								<p v-else-if="data.other_tutor_type" class="data">
									{{ data.other_tutor_type  + (data.preferred_budget == 1 ? ' ($' + data.tutor_rate + (data.service_id == 5 ? '/session)' : '/hr)') : '') }}
								</p>
							</div>
							<div class="col-12 col-md-4 mb-4">
								<p class="label">Student level / grade</p>
								<p class="data">{{ data.student_level }}</p>
							</div>
							<div class="col-12 col-md-4 mb-4">
								<p class="label">Type of lesson</p>
								<p class="data">{{ capitalizeFirstLetter(data.type_of_lesson ?? '-') }}</p>
							</div>
							<div class="col-12 col-md-4 mb-4">
								<p class="label">Address</p>
								<p class="data">{{ data.address ?? '-' }}</p>
							</div>
							<div class="col-12 col-md-4 mb-4">
								<p class="label">Postal code</p>
								<p class="data">{{ data.postal ?? '-' }}</p>
							</div>
							<div class="col-12 col-md-4 mb-4">
								<p class="label">Unit No.</p>
								<p class="data">{{ data.unit ?? '-' }}</p>
							</div>
							<div class="col-12 col-md-4 mb-4">
								<p class="label">Available time slots</p>
								<p class="data">{{ data.preferred_time ?? '-' }}</p>
							</div>
						</div>

						<div class="row mt-5">
							<div class="col-12 col-md-4 mb-4">
								<p class="label" v-if="data.service_id == 1 || data.service_id == 2">Tutor types</p>
								<p class="label" v-else-if="data.service_id == 3 || data.service_id == 4">Service professionals</p>
								<p class="label" v-else-if="data.service_id == 5">Enrichment specialist</p>
								<p class="label" v-else>Service professional</p>
								<p v-if="formattedTutorInfo && data.other_tutor_type" class="data">
									{{ formattedTutorInfo + ', ' + data.other_tutor_type + (data.preferred_budget == 1 ? ' $(' + data.tutor_rate + '/hr)' : '') }}
								</p>
								<p v-else-if="formattedTutorInfo" class="data">
									{{ formattedTutorInfo }}
								</p>
								<p v-else-if="data.other_tutor_type" class="data">
									{{ data.other_tutor_type + (data.preferred_budget == 1 ? ' $(' + data.tutor_rate + '/hr)' : '') }}
								</p>
							</div>

							<div class="col-12 col-md-4 mb-4">
								<p class="label">Gender Preference</p>
								<p class="data">{{ formatString(data.gender_preference ?? '-') }}</p>
							</div>

							<div class="col-12 col-md-4 mb-4">
								<p class="label">Estimated commencement date</p>
								<p class="data">{{ data.estimated_commencement_date ?? '-' }}</p>
							</div>
						</div>
						
						<div class="row">
							<div class="col-12 col-md-4 mb-4">
								<p class="label">Remarks</p>
								<p class="data">{{ data.remarks ?? '-' }}</p>
							</div>
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-body">
						<div class="change_tutor_row">
							<p class="card__title mb-0">Applicant List</p>

							<button v-if="ableToChangeTutor" class="change_tutor_btn" @click="changeTutor">
                                <i class="mdi mdi-sync font-size-15"></i>
                                Reset & Update Assignment to Open
                            </button>
						</div>

						<table v-if="applicantList.length" style="display: block; overflow-x: auto;">
							<tr align="center">
								<th>Service Provider name</th>
								<th>Applied date</th>
								<th>Preferred budget</th>
								<th>Earliest commencement date</th>
								<th>Availability</th>
								<th></th>
								<th></th>
							</tr>
							<tr v-for="applicant in applicantList" :key="applicant.id" align="center">
								<td>
									<router-link class="mr-2 text-primary tutor_link"
										:to="{ name: 'Tutor View', params: { id: applicant.tutor_id } }" target="_blank">
										{{ applicant.name }}
									</router-link>
								</td>
								<td>{{ formatDate(applicant.created_at) }}</td>
								<td>${{ applicant.budget }} per hour</td>
								<td>{{ formatDate(applicant.latest_commencement_date) }}</td>
								<td>{{ applicant.availability ?? '-' }}</td>
								<td v-if="applicant.status === 'pending'">
									<i class="mdi mdi-check-circle-outline font-size-18 pl-2" style="color: #58CC02;" v-b-tooltip.hover title="Approve" @click="approve(applicant)"></i>
								</td>
								<td v-else-if="applicant.status === 'assigned'">
									<div class="status-green">Assigned</div>
								</td>
								<td v-else-if="applicant.status === 'denied'">
									<div class="status-red">Denied</div>
								</td>
								<td>
									<i class="mdi mdi-eye font-size-18" v-b-tooltip.hover title="View" @click="viewApplication(applicant)"></i>
								</td>
							</tr>
						</table>
						<p v-else>No applicants yet.</p>
					</div>
				</div>

				<div class="card">
					<div class="card-body">
						<p class="card__title">Requestor info</p>

						<div class="row">
							<div class="col-12 col-md-4 mb-4">
								<p class="label">Parent Name</p>
								<p class="data">{{ data.requestor_name ?? '-' }}</p>
							</div>
							<div class="col-12 col-md-4 mb-4">
								<p class="label">Student Name</p>
								<p class="data">{{ data.student_name }}</p>
							</div>
							<div class="col-12 col-md-4 mb-4">
								<p class="label">Contact number</p>
								<p class="data">{{ data.phone }}</p>
							</div>
							<div class="col-12 col-md-4 mb-4">
								<p class="label">Email address</p>
								<p class="data">{{ data.email ?? '-' }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>		
		</div>		
	</Layout>
</template>

<style scoped>
.card-body {
	padding: 36px 48px;

	& .card__title {
		color: #505D69;
		font-family: 'Inter';
		font-weight: 700;
		font-size: 18px;
		line-height: 27px;
		border-left: 5px solid #38B6FF;
		padding-left: 8px;
		margin-bottom: 36px;
	}

	& .label {
		font-family: Nunito;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		color: #505D69;
		margin-bottom: 0;
	}
	
	& .data {
		font-family: Nunito;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #505D69;
		margin-bottom: 0;
	}

	table tr {
		border-bottom: 1px solid rgba(205, 205, 205, 0.5);
	}

	table th,
	table td {
		padding: 12px;
	}

	& .tutor_link {
		color: #004AAD;
		text-decoration: underline !important;
		cursor: pointer;
	}

	table img {
		cursor: pointer;
	}
}
</style>